<template><div></div></template>

<script setup lang="ts">
    import {onMounted} from 'vue';
    import {useRoute, useTranslation} from '#imports';
    import {pushVJoySnackbar} from '@maltjoy/core-vue';

    const $route = useRoute();
    const {t} = useTranslation();
    const message = t('company.invitation.unsubscribe.confirmation.banner');
    const displayBanner = $route.query.inviteUnsubscribe === 'true';

    if (displayBanner) {
        onMounted(() => {
            pushVJoySnackbar({
                props: {
                    message,
                    closable: false,
                    class: 'confirm-unsubscribe-invitation-email-snackbar',
                    duration: 10000,
                },
            });
        });
    }
</script>

<style lang="scss" scoped>
    .confirm-unsubscribe-invitation-email-snackbar {
        font-size: var(--joy-font-size-primary-300);
    }
</style>
