<template><div></div></template>

<script setup lang="ts">
    import {onMounted, onUnmounted} from 'vue';
    import {pushVJoySnackbar} from '@maltjoy/core-vue';
    import {useCookie, useHostFeatures, useHostSettings, useTranslation} from '#imports';

    const cookieName = 'country-launching-banner';
    const flagName = 'malt-launching-banner';
    const {activeFeatures} = (await useHostFeatures()) || {};
    const isBannerSeen = useCookie(cookieName).value;
    const maltLaunchingBanner = activeFeatures?.includes(flagName);
    const displayBanner = maltLaunchingBanner && !isBannerSeen;

    const countryFlags: Record<string, string> = {
        AT: '🇦🇹',
        BE: '🇧🇪',
        CH: '🇨🇭',
        DE: '🇩🇪',
        DK: '🇪🇺',
        ES: '🇪🇸',
        FR: '🇫🇷',
        HU: '🇭🇺',
        NL: '🇳🇱',
        PT: '🇵🇹',
        UK: '🇬🇧',
        GB: '🇬🇧',
        AE: '🇦🇪',
    };

    const {country, countryName, customerSupportEmail} = (await useHostSettings()) || {};
    const countryFlag = country && countryFlags[country];

    const {t} = useTranslation();
    const message = t('country.launching.banner', [countryName, countryFlag, customerSupportEmail]);

    function markBannerAsSeen() {
        document.cookie = `${cookieName}=seen;path=/`;
    }

    if (displayBanner) {
        let snackbarCloseBtn: HTMLElement | null;
        onMounted(() => {
            const snackbarClassname = 'new-country-snackbar';
            pushVJoySnackbar({
                props: {
                    message,
                    closable: true,
                    class: snackbarClassname,
                    duration: 30000,
                },
            });
            snackbarCloseBtn = document.querySelector(`.${snackbarClassname} .joy-snackbar > button`);
            snackbarCloseBtn?.addEventListener('click', markBannerAsSeen);
        });
        onUnmounted(() => {
            snackbarCloseBtn?.removeEventListener('click', markBannerAsSeen);
        });
    }
</script>

<style lang="scss" scoped>
    .new-country-snackbar {
        font-size: var(--joy-font-size-primary-300);
    }
</style>
