import {useLogger, useUniversalFetch} from '#imports';
import type {HostFeaturesResource} from '../types';

export default function useHostFeatures(): Promise<HostFeaturesResource | undefined> {
    const logger = useLogger();

    return useUniversalFetch<HostFeaturesResource>('/api/host-features/current').catch((error: unknown) => {
        logger.error(`Error while fetching host features: ${error}`);
        return undefined;
    });
}
